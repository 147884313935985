<template>
  <div id="app" @click="tanChuang = false">
    <el-dialog
      top="10%"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="65%"
    >
      <div style="padding: 0 10px;box-sizing: border-box;height: 20px;overflow:hidden;" slot="title" v-html="title"></div>
      <iframe
        v-if="dialogVisible"
        style="width: 100%; height: 400px; object-fit: fill"
        scrolling="auto"
        allowfullscreen="true"
        mozallowfullscreen="true"
        :src="resourseSrc"
        frameborder="0"
      ></iframe>
    </el-dialog>
    <div id="chart" class="chart"></div>
    <!-- :title="knowledge_point_name"  -->
    <div class="tanChuang" v-show="tanChuang" @click.stop="">
      <div class="name">{{ knowledge_point_name }}</div>
      <div class="btn">
        <button class="button" @click.stop="getNodes">节点分析</button>
        <button class="button" @click.stop="getResource">节点资源</button>
        <button class="button" @click.stop="getQuestion">节点习题</button>
      </div>
      <div class="content">
        <div class="list">
          <div>关联学习资源数</div>
          <div>{{ resource_num }}</div>
        </div>
        <div class="list">
          <div>关联试题数</div>
          <div>{{ question_num }}</div>
        </div>
        <div class="knowlege">知识点分析</div>
        <div class="twoBox">
          <p>知识点名称</p>
          <p>操作</p>
        </div>
        <div class="child">
          <div
            class="one two"
            v-for="(item, index) in up_one_leve"
            :key="index"
          >
            <div>
              <span>前置</span>
              <p :title="item.name">{{ item.name }}</p>
            </div>
            <div @click="chaKan(item.id)">查看</div>
          </div>
          <div class="one tre" v-for="item in same_level" :key="item.id">
            <div>
              <span>同级</span>
              <p :title="item.name">{{ item.name }}</p>
            </div>
            <div @click="chaKan(item.id)">查看</div>
          </div>
          <div class="one" v-for="item in next_ower_level" :key="item.id">
            <div>
              <span>子级</span>
              <p :title="item.name">{{ item.name }}</p>
            </div>
            <div @click="chaKan(item.id)">查看</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div>关联学习资源数</div>
          <div>{{ resource_num }}</div>
        </div>
        <div class="wenjian">
          <p>文件</p>
          <p>类型</p>
        </div>
        <div class="wenjianList">
          <div
            class="ppt"
            v-for="(item, index) in resource_arr"
            :key="index"
            @click="getresouseInfo(item.resourceId, item.title)"
          >
            <div class="top">
              <div>
                <div>
                  <div>
                    <img
                      v-show="item.type == 'PPT演示文稿'"
                      id="ppt"
                      src="./assets/PPT.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '视频类'"
                      id="mp4"
                      src="./assets/mp4.png"
                      alt=""
                    />
                    <img
                      v-show="item.type.indexOf('图形') !== -1"
                      id="tupian"
                      src="./assets/tupian.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '音频类'"
                      id="yinpin"
                      src="./assets/yinpin.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '文本类'"
                      id="T"
                      src="./assets/T.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '网址课件'"
                      id="pdf"
                      src="./assets/pdf (1).png"
                      alt=""
                    />

                    <img
                      v-show="item.type == '网页课件'"
                      id="wangzhikejian"
                      src="./assets/wangzhikejian.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '交互课件'"
                      id="jiaohukejian"
                      src="./assets/jiaohukejian.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '其他'"
                      id="qita"
                      src="./assets/qita.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '动画类'"
                      id="donghualei"
                      src="./assets/donghualei.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '虚拟仿真类'"
                      id="xunilei"
                      src="./assets/xunilei.png"
                      alt=""
                    />
                    <img
                      v-show="item.type == '微课'"
                      id="weike"
                      src="./assets/weike.png"
                      alt=""
                    />
                  </div>
                  <p v-html="item.title" class="title"></p>
                </div>
                <div :title="item.type">{{ item.type }}</div>
              </div>
            </div>
            <div class="bottom">
              <span v-for="(it, ind) in item.knowldgeList" :key="ind"
                >{{ it.name }},</span
              >
            </div>
          </div>
          <!-- <div class="ppt" v-for="(item,index) in resource_arr" :key="index">
        <div class="top">
          <div>
            <div>
              <div>
                <img v-show="item.type=='PPT演示文稿'" id="ppt" src="./assets/PPT.png" alt="">
                <img v-show="item.type=='视频类'" id="mp4" src="./assets/mp4.png" alt="">
                <img v-show="item.type.indexOf('图形')!==-1" id="tupian" src="./assets/tupian.png" alt="">
                <img v-show="item.type=='音频类'" id="yinpin" src="./assets/yinpin.png" alt="">
                <img v-show="item.type=='文本类'" id="T" src="./assets/T.png" alt="">
                <img v-show="item.type=='网址课件'" id="pdf" src="./assets/pdf (1).png" alt="">

                <img v-show="item.type=='网页课件'" id="wangzhikejian" src="./assets/wangzhikejian.png" alt="">
                <img v-show="item.type=='交互课件'" id="jiaohukejian" src="./assets/jiaohukejian.png" alt="">
                <img v-show="item.type=='其他'" id="qita" src="./assets/qita.png" alt="">
                <img v-show="item.type=='动画类'" id="donghualei" src="./assets/donghualei.png" alt="">
                <img v-show="item.type=='虚拟仿真类'" id="xunilei" src="./assets/xunilei.png" alt="">
                <img v-show="item.type=='微课'" id="weike" src="./assets/weike.png" alt="">
              </div>
              <p :title="item.title " class="title">{{ item.title }}</p>
            </div>
            <div :title="item.type">{{ item.type }}</div>
          </div>
        </div>
        <div class="bottom"><span v-for="(it,ind) in item.knowldgeList" :key="ind">{{ it.name }},</span></div>
       </div> -->
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div>关联习题</div>
          <div>{{ question_num }}</div>
        </div>
        <div class="wenjian">
          <p>题目</p>
          <p>类型</p>
        </div>
        <div class="wenjianList">
          <div
            class="ppt"
            v-for="(item, index) in question_arr"
            :key="index"
            @click="getquestionInfo(item.qid, item.title)"
          >
            <div class="top">
              <div>
                <div>
                  <div>
                    <img id="mp4" src="./assets/tiankong.png" alt="" />
                  </div>
                  <p v-html="item.title" class="title"></p>
                </div>
                <div :title="item.type">{{ item.type }}</div>
              </div>
            </div>
            <div class="bottom">
              <span v-for="(it, ind) in item.knowldgeList" :key="ind"
                >{{ it.name }},</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { expendNodes } from "./assets/js/mock";
// import { postData } from "@/axios/api";
import axios from "axios";
import { Loading } from "element-ui";
var echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/graph");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
export default {
  name: "Charts",
  // props: {
  //   chartList: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  watch: {
    chartList: {
      handler(val) {
        this.formatData(val || [], true);
      },
    },
  },
  data() {
    return {
      // 弹窗标题
      title: "",
      //弹窗
      dialogVisible: false,
      //资源地址
      resourseSrc: "",
      //节点名称
      knowledge_point_name: "",
      //判断选中的哪个模块
      selectNum: 1,
      //节点资源列表
      resource_arr: [],
      //节点资源数量
      resource_num: "",
      //节点习题列表
      question_arr: [],
      //节点习题数量
      question_num: "",
      //同级节点
      same_level: [],
      //上级节点
      up_one_leve: [],
      //下级节点
      next_ower_level: [],
      //统一配置axios
      http: null,
      //左侧弹窗是否展示
      tanChuang: false,
      // neirong: "",
      //数据列表
      chartsList: {
        seriesData: [],
        linkData: [],
        // seriesData: [{
        // 	id: "2eb59376-89af-3f63-89ca-07f337ad7358",
        // 	name: "中国娃",
        // 	root: false
        // }, {
        // 	id: "6fc3ffcb-0d55-37d7-977e-8897862054e3",
        // 	name: "123木头人",
        // 	root: false
        // }, {
        // 	id: "b8afab6d-67fe-301c-9c21-f8c9ebd84f0b",
        // 	name: "第一章",
        // 	root: true
        // }, {
        // 	id: "bb10804b-1e31-3472-9888-611374ffc977",
        // 	name: "美丽",
        // 	root: false
        // }, {
        // 	id: "d41781fa-8f15-3ecf-b28d-f819e1a3dab7",
        // 	name: "很好",
        // 	root: false
        // }],
        // linkData: [{
        // 	source: "b8afab6d-67fe-301c-9c21-f8c9ebd84f0b",
        // 	target: "2eb59376-89af-3f63-89ca-07f337ad7358",
        // 	name: "中国娃"
        // }, {
        // 	source: "2eb59376-89af-3f63-89ca-07f337ad7358",
        // 	target: "6fc3ffcb-0d55-37d7-977e-8897862054e3",
        // 	name: "123木头人"
        // }, {
        // 	source: "0",
        // 	target: "b8afab6d-67fe-301c-9c21-f8c9ebd84f0b",
        // 	name: "第一章",
        // }, {
        // 	source: "b8afab6d-67fe-301c-9c21-f8c9ebd84f0b",
        // 	target: "bb10804b-1e31-3472-9888-611374ffc977",
        // 	name: "美丽"
        // }, {
        // 	source: "b8afab6d-67fe-301c-9c21-f8c9ebd84f0b",
        // 	target: "d41781fa-8f15-3ecf-b28d-f819e1a3dab7",
        // 	name: "很好"
        // }]
      },
      //初始化echarts
      myChart: "",
      seriesData: [],
      seriesLinks: [],
      // categories: [],
      // lastClickId: "",
      // colors: [
      //   "#a3d2ca",
      //   "#056676",
      //   "#ea2c62",
      //   "#16a596",
      //   "#03c4a1",
      //   "#f5a25d",
      //   "#8CD282",
      //   "#32e0c4",
      //   "#00FAE1",
      //   "#f05454",
      // ],
    };
  },
  created() {
    this.http = axios.create({
      baseURL: this.$route.query.url
        ? this.$route.query.url
        : "http://www.test4.36ve.com",
      timeout: 300000, // 请求超时时间
    });
    let loading,
      loadingCount = 0;

    function startLoading() {
      loading = Loading.service({
        lock: true,
        text: "加载中……",
        // background: "red"
        background: "rgba(0, 0, 0, 0.1)",
      });
    }
    function endLoading() {
      loading.close();
    }
    function showLoading() {
      if (loadingCount === 0) {
        startLoading();
      }
      loadingCount += 1;
    }
    function hideLoading(value) {
      if (value == "成功") {
        loadingCount -= 1;
        if (loadingCount === 0) {
          endLoading();
        }
      } else if (value == "网络错误，请检查网络设置！") {
        loadingCount -= 1;
        if (loadingCount === 0) {
          endLoading();
        }
      } else {
        endLoading();
      }
    }

    // 请求拦截器
    this.http.interceptors.request.use(
      (config) => {
        showLoading();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截器
    this.http.interceptors.response.use(
      (response) => {
        if (
          response.data.code != 200 &&
          response.data.code != 404 &&
          response.data.response_code != 200 &&
          response.data.code != 601
        ) {
          hideLoading(response.data.msg);
          return Promise.reject(response.data);
        } else {
          hideLoading("成功");
          // console.log(response);
          return response;
        }
      },
      (error) => {
        hideLoading("网络错误，请检查网络设置！");
        console.log(error);
        // alert('网络错误，请检查网络设置！')
        this.$message.error(error);
        return Promise.reject(error);
      }
    );
    this.getEchartsData();
  },
  mounted() {
    // this.getNodes();
    var btnlist = document.querySelectorAll(".button");
    var content = document.querySelectorAll(".content");
    let a = 0;
    btnlist[a].style.border = "1px solid #00FFFF";
    btnlist[a].style.boxShadow = "0px 0px 7px 1px #00FFFF";
    btnlist[a].style.color = "#00FFFF";
    content[a].style.display = "flex";
    for (let i = 0; i < btnlist.length; i++) {
      btnlist[i].onclick = function () {
        btnlist[a].style.border = "1px solid #FFFFFF";
        btnlist[a].style.boxShadow = "none";
        btnlist[a].style.color = "#FFFFFF";
        content[a].style.display = "none";

        btnlist[i].style.border = "1px solid #00FFFF";
        btnlist[i].style.boxShadow = "0px 0px 7px 1px #00FFFF";
        btnlist[i].style.color = "#00FFFF";
        content[i].style.display = "flex";

        a = i;
      };
    }
  },
  methods: {
    getquestionInfo(id, title) {
      this.dialogVisible = true;
      this.title = title;
      this.http
        .get(
          "index.php/LearningCenter/knowledge-picture/get-question-detail?resource_id=" +
            id+'&type='+this.$route.query.category
        )
        .then((res) => {
          this.resourseSrc = res.data.data.url;
        });
    },
    getresouseInfo(id, title) {
      this.dialogVisible = true;
      this.title = title;
      this.http
        .get(
          "index.php/LearningCenter/knowledge-picture/get-resource-detail?resource_id=" +
            id
        )
        .then((res) => {
          this.resourseSrc = res.data.data.url;
        });
    },
    chaKan(id) {
      this.courseid = id;
      this.getNodes();
    },
    getQuestion() {
      this.selectNum = 3;
      this.http
        .get(
          "/index.php/LearningCenter/knowledge-picture/get-knowledge-question-info?id=" +
            this.courseid +
            "&type=" +
            this.$route.query.type +
            "&bcourseId=" +
            this.$route.query.bcourseId
        )
        .then((res) => {
          this.tanChuang = true;
          this.knowledge_point_name = res.data.data.knowledge_point_name;
          this.question_num = res.data.data.num;
          // this.tanChuang = true;
          // console.log(res.data.data)
          this.question_arr = res.data.data.data;
        });
    },
    getResource() {
      this.selectNum = 2;
      this.http
        .get(
          "/index.php/LearningCenter/knowledge-picture/get-knowledge-resource-info?id=" +
            this.courseid +
            "&type=" +
            this.$route.query.type +
            "&bcourseId=" +
            this.$route.query.bcourseId
        )
        .then((res) => {
          this.tanChuang = true;
          this.knowledge_point_name = res.data.data.knowledge_point_name;
          this.resource_num = res.data.data.num;
          // console.log(res.data.data)
          this.resource_arr = res.data.data.data;
        });
    },
    getNodes() {
      this.selectNum = 1;
      this.http
        .get(
          "/index.php/LearningCenter/knowledge-picture/get-knowledge-point-num?id=" +
            this.courseid +
            "&type=" +
            this.$route.query.type +
            "&bcourseId=" +
            this.$route.query.bcourseId
        )
        .then((res) => {
          this.tanChuang = true;
          // console.log(res.data.data)
          this.knowledge_point_name = res.data.data.knowledge_point_name;
          this.resource_num = res.data.data.resource_num;
          this.question_num = res.data.data.question_num;
          this.same_level = res.data.data.same_level;
          this.up_one_leve = res.data.data.up_one_leve;
          this.next_ower_level = res.data.data.next_ower_level;
        });
    },
    getEchartsData() {
      if (this.$route.query.courseid) {
        this.http
          .get(
            "index.php/LearningCenter/knowledge-picture/get-konwledge-pic?course_id=" +
              this.$route.query.courseid +
              "&type=" +
              this.$route.query.type +
              "&bcourseId=" +
              this.$route.query.bcourseId
          )
          .then((res) => {
            // console.log(res.data.data)
            this.chartsList = res.data.data;
            this.formatData();
            // console.log(this.chartsList)
          });
      }
    },
    /**
     * 节点点击事件
     */
    async nodeClick(params) {
      // const index = this.seriesData.find((item) => item.id === params.data.id);
      // this.tanChuang=true
      this.courseid = params.data.id;
      if (this.selectNum === 1) {
        this.getNodes();
      } else if (this.selectNum === 2) {
        this.getResource();
      } else {
        this.getQuestion();
      }
    },
    /**
     * 设置echarts配置项,重绘画布
     */
    initCharts() {
      const that = this;
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById("chart"));
        this.myChart.on("click", (params) => {
          if (params.dataType === "node") {
            //判断点击的是图表的节点部分
            this.nodeClick(params);
          }
        });
      }
      // 指定图表的配置项和数据
      let option = {
        // 动画更新变化时间
        animationDurationUpdate: 500,
        animationEasingUpdate: "quinticInOut",
        edgeLabel: {
          position: "middle", //边上的文字样式
          // normal: {
          show: true,

          fontSize: 12,

          position: "middle",
          formatter: function (x) {
            return x.data.name;
          },
          // },
        },

        tooltip: {
          show: false,
        },
        series: [
          {
            type: "graph",
            // symbol:"rect",
            layout: "force",
            legendHoverLink: true, //是否启用图例 hover(悬停) 时的联动高亮。
            scale: true, //是否开启鼠标悬停节点的显示动画
            // focusNodeAdjacency: true,
            emphasis: { focus: "adjacency" },

            edgeSymbol: ["", "arrow"],
            force: {
              // edgeLength: 15,
              repulsion: 2000,
              gravity: 1,
              // edgeLength:700,
              layoutAnimation: true,
              friction: 0.1,
            },
            roam: true,
            draggable: true, //每个节点的拖拉

            itemStyle: {
              // normal: {
              color: "#00FAE1",
              cursor: "pointer",
              //color:Math.floor(Math.random()*16777215).toString(16),
              //color: ['#fc853e','#28cad8','#9564bf','#bd407e','#28cad8','#fc853e','#e5a214'],

              nodeStyle: {
                brushType: "both",
                borderColor: "rgba(255,215,0,0.4)",
                borderWidth: 1,
              },
              // },
              //鼠标放上去有阴影效果
              emphasis: {
                shadowColor: "#00FAE1",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 40,
              },
            },
            lineStyle: {
              color: "rgb(255,255,255)",
              width: 2,
            },
            label: {
              show: true,
              align: "center",
              // position: [-10, -15],
              position: "top",

              // textStyle: {
              //标签的字体样式
              color: "#00FAE1", //字体颜色
              fontStyle: "normal", //文字字体的风格 'normal'标准 'italic'斜体 'oblique' 倾斜
              fontWeight: "bold", //'normal'标准'bold'粗的'bolder'更粗的'lighter'更细的或100 | 200 | 300 | 400...
              fontFamily: "sans-serif", //文字的字体系列
              fontSize: 12, //字体大小
              // },
            },
            symbolSize: 24, //节点大小
            links: this.seriesLinks,
            data: this.seriesData,
            categories: this.categories,
            cursor: "pointer",
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    /**
     * 格式化数据到表格需要的数据
     */
    formatData(list, reset = false) {
      const that = this;
      let nodes = this.chartsList.seriesData;
      this.seriesData = [];
      this.seriesLinks = [];
      // let colorIndex = 0;
      let data = [];
      // let loadedCat = [];
      // let arr = [];
      nodes.forEach((item, index) => {
        // if (item.categary && loadedCat.indexOf(item.categary) === -1) {
        //   colorIndex = Math.floor(Math.random() * that.colors.length);
        //   loadedCat.push(item.categary);
        //   this.categories.push({ name: item.categary });
        // }
        if (item.root) {
          // item.symbolSize= 30,
          item.symbol =
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAADRklEQVRogdXZS4gcRRwG8N+sD3ZX9LBuDhpRVBISlagoiOBJ8BBQDx4EFfERwUsSUA9G0LMriHpSUIhePHgNgrecPBiIEOMT3EhA3CCLhyhZk30YD1XNsrMzs1U9VZPJB03PVP+r/9/XX1fXq7NgRmU8HM9HaybpjEDIN/H8IC7WSjJR68YRj+OBeDxWM1FNRybwHe6K/3/A3fivVrJaeMq6CPH307WS1XLkKvyM27vKT2E3VkonrOXIizaLEMteqpGwhiNT+BXb+1z/Azvwb8mkNRzZr78I8dr+0klLO3Kd0A5mt4j7C7fh71KJSzvymq1FwPUxthhKOjKL33BtYvw/QuNfLJG8pCNvSBchxh4qlbyUIzcJX6rJzHrnsRO/D0uglCNvyRch1nmzBIFhHbkGD+FLXNnyHqt4FF/jXFsiOUKmcA/uw/3xvBtXtE3ehTX8guPx+BYnJHac/YRMYo9AuCF9h/ZPvS1W8ZMgqhF4UmhbG9BZMHO1ddLN075TGPiNI1bwo3XXjuNkZ8HMYbxwKZkVwGeNI19Zn1tfbjiKvRNYxhPCbO5yw/cC9+WmHzmLRzB/ySjlY17gfJaNHeIi9io09qmMhuufTUF3zz4vrHYsjZBULpYEjhvenl5DlGPCwsHaCEjlYk3gdqz7Qr+x1hEcqMmoJQ4I3DZh0KDxI8xVodMOcwKnnthqrNXBp3iuMKlcfI5nDVhy3WoYfxH79LFzRDgiPMiB68Yp85G+DWwESP7wpE6sen7yKiOrK8iZIS7i7TaMWmJORuecO9W9MTN+GNyQE5wr5ObM+GGQlWuchdySEzzOQqo6kvWUhkQ1IbOYzuMyFKaxLTU4R8go3WiQ7EqOkFG2j+yc4y4k+S3IWXBr82qtCiNXeCYzH2PgyAoOYxeej8euWJazo5v88EoLWcbHwlbBPmEbrsGpWLYzxiwXyolyX60L+FDYrX0ZpwfEno4xO2KdCwNik4WkrsZP6r0qfh6f4B1h27kNtuN1Yf99qsf16T65NyDVkW43lvCBsDN7UHsRYt2Dwn7i+zbPP5JcSRXS3Owc3hUEvIIzifVTcAav4taYo9n0SRKS+mo9iXvxntGtRG4ThJ3AF1sF/w+1LKB9eWZxggAAAABJRU5ErkJggg==";
        }
        //  "#a3d2ca",
        item.itemStyle = {
          color: "#00FAE1",
          borderColor: "#ffffff",
          type: "square",
        };
        data.push(item);
      });
      // this.chartsList.linkData.lineStyle={
      //   color:'red'
      // }
      this.seriesData.push(...data);
      this.seriesLinks.push(...this.chartsList.linkData);
      this.initCharts();
    },
  },
  beforeDestroy() {},
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#app {
  position: relative;
}
.chart {
  height: 100%;
  width: 100%;
  background-image: url(./assets/bj.png);
}
@keyframes ani {
  from {
    right: -25%;
  }

  to {
    right: 0;
  }
}
.tanChuang {
  width: 25%;
  min-width: 400px;
  height: 94%;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-image: url(./assets/59.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  animation: ani ease 1s forwards;
  display: flex;
  flex-direction: column;
}
.name {
  width: 46%;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #00ffff;
  margin: auto;
  margin-top: 0.5%;
  overflow: hidden;
  /* text-align: right; */
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin-top: 3.2%; */
}
.btn {
  width: 77%;
  margin: auto;
  /* margin-top: 30px; */
  display: flex;
  justify-content: space-between;
}
.btn > button {
  height: 37px;
  width: 26%;
  background: rgba(0, 255, 255, 0);
  border: 1px solid #ffffff;
  /* border: 1px solid #00FFFF;
  box-shadow: 0px 0px 7px 1px #00FFFF; */
  font-size: 16px;
  /* color: #00FFFF; */
  color: #ffffff;
  cursor: pointer;
}
.content {
  display: none;
  /* flex: 1; */
  height: 76%;
  margin: auto;
  /* margin-top: 20px; */
  width: 77%;
  overflow: hidden;
  /* display: flex; */
  flex-direction: column;
}
.list {
  width: 100%;
  height: 66px;
  border: 1px solid #00eaff;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #00ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  box-sizing: border-box;
}
.knowlege {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  color: #00ffff;
  margin: 20px 0;
}
.twoBox {
  margin: auto;
  width: 100%;
  height: 34px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: RGBA(0, 117, 128, 0.8);
  padding: 0 22px;
  box-sizing: border-box;
}
.child {
  margin-top: 20px;
  width: 100%;
  /* height:32%; */
  flex: 1;
  /* height: 400px; */
  /* display: flex;
  justify-content: space-between; */
  padding: 0 20px 0px 30px;
  box-sizing: border-box;
  color: #ffffff;
  overflow: auto;
}
.child::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.child::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff;
}
.child::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.child > div {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}
.child > div > div:nth-child(1) {
  display: flex;
  align-items: center;
  /* background: red; */
  width: 83%;
}
.child > div > div:nth-child(2) {
  cursor: pointer;
}
.child > .two > div > span {
  color: #d90000;
}
.child > .tre > div > span {
  color: #009944;
}
.child > div p {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 100px; */
}
.child > div > div > span {
  width: 32px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: RGBA(15, 43, 61, 0.9);
  border-radius: 2px;
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #4da1ff;
  margin-right: 5px;
}
/*  */
.wenjian {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 800;
  color: #00ffff;
  padding-right: 12px;
  box-sizing: border-box;
}
.wenjian > p:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
.wenjianList {
  margin-top: 20px;
  width: 100%;
  flex: 1;
  /* height:66%; */
  /* display: flex;
  justify-content: space-between; */
  color: #ffffff;
  overflow: auto;
  padding-right: 12px;
  box-sizing: border-box;
}
.wenjianList::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.wenjianList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff;
}
.wenjianList::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.ppt {
  width: 100%;
  cursor: pointer;
  /* height: 46px;
  background: red; */
}
.ppt > .top {
  height: 46px;
  width: 100%;
  /* background: red; */
}
.ppt > .top > div {
  /* background: red; */
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ppt > .top > div > div:nth-child(1) {
  width: 75%;
  display: flex;
  align-items: center;
}
.ppt > .top > div > div:nth-child(2) {
  width: 20%;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ppt > .top > div > div > div:nth-child(1) {
  width: 46px;
  height: 46px;
  background: #34abff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
#ppt {
  width: 23px;
  height: 22px;
}
#mp4 {
  width: 20px;
  height: 20px;
}
#tupian {
  width: 28px;
  height: 17px;
}
#yinpin {
  width: 14px;
  height: 20px;
}
#T {
  width: 16px;
  height: 21px;
}
#T {
  width: 24px;
  height: 23px;
}
#wangzhikejian {
  width: 21px;
  height: 20px;
}
#jiaohukejian {
  width: 19px;
  height: 20px;
}
#qita {
  width: 21px;
  height: 5px;
}
#donghualei {
  width: 17px;
  height: 20px;
}
#xunilei {
  width: 26px;
  height: 20px;
}
#weike {
  width: 22px;
  height: 20px;
}
.bottom {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}
.title > * {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
