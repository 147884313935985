import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决导航沉余的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path:''
  }
]
const router = new VueRouter({
  routes,
});
export default router;
